<template> 
  <button :class="[colourStyles, paddingStyles]"
           class="transition 
                 duration-300 
                 ease-in-out 
                 text-center
                 rounded-full
                 text-white">
  {{ buttonText }}
  </button>
</template>

<script>
// vue
import { computed } from 'vue'

export default {
  props: [
    'buttonText',
    'darkMode',
    'paddingX',
    'paddingY'
  ],
  setup(props) {
    // computed
    const colourStyles = computed(() => props.darkMode ? 'bg-grey hover:bg-white hover:text-grey' : 'bg-red hover:bg-white hover:text-red')
    const paddingStyles = computed(() => props.paddingX && props.paddingY ? `py-${props.paddingY} px-${props.paddingX}` : 'py-2 px-4')

    return {
      colourStyles,
      paddingStyles
    }
  }
}
</script>
<template>

  <div class="pb-16
              md:pb-20
              z-1
              relative
              bg-verylightgrey">

    <div class="px-16
                md:px-28">

      <div class="pt-16
                  md:pt-28
                  md:container 
                  md:mx-auto
                  md:grid
                  md:grid-cols-4">

        <h1 class="text-3xl
                   text-grey
                   mb-10
                   md:mb-10
                   md:col-start-1
                   md:col-end-3">
          THE TEAM
        </h1>

      </div>
      
      <div class="pt-16
                  md:pt-5
                  md:container 
                  md:mx-auto">

        <div class="mb-10 
                    md:flex
                    md:gap-16
                    lg:grid-cols-4
                    lg:gap-16">

          <TeamShortProfile fullName="Drew Lasker"
                            :profileImage="drewLaskerImage"
                            role="Co-Founder, President"
                            profileURL="drew-lasker"
                            :socialMediaURLS="{
                              linkedIn: 'https://www.linkedin.com/in/drewlasker/',
                              instagram: 'https://www.instagram.com/drewlasker21/',
                              facebook: 'https://www.facebook.com/drewlasker21'
                              // twitter: '#',
                            }" />

          <TeamShortProfile fullName="Yvonne Harris"
                            :profileImage="yvonneHarrisImage"
                             role="Co-Founder, Executive Director"
                             profileURL="yvonne-harris"
                            :socialMediaURLS="{
                              linkedIn: 'https://www.linkedin.com/in/ynharris/',
                              facebook: 'https://www.facebook.com/yvonne.harris.92798'
                            }" />

        </div>

        <router-link class="navigationLink
                            underline" 
                    :to="{ name: 'Team' }">
          Learn more about the team
        </router-link>

      </div>

    </div>
      
  </div>

</template>

<script>
import drewLaskerImage from '@/assets/images/profiles/drew-lasker.jpg'
import yvonneHarrisImage from '@/assets/images/profiles/yvonne-harris.jpg'

import linkedInIcon from '@/assets/images/icons/linkedInIcon.svg'
import instagramIcon from '@/assets/images/icons/instagramIcon.svg'
import twitterIcon from '@/assets/images/icons/twitterIcon.svg'
import facebookIcon from '@/assets/images/icons/facebookIcon.svg'

import TeamShortProfile from './TeamShortProfile.vue'

export default {
  name: 'TeamComponent',
  components: {
    TeamShortProfile
  },
  setup() {
    return {
      drewLaskerImage,
      yvonneHarrisImage,
      linkedInIcon,
      instagramIcon,
      twitterIcon,
      facebookIcon
    }
  }
}

</script>
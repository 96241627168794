import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAt5Uo7VinKbgIhlcQAZ7Gen0zw83xpnjk",
  authDomain: "twenty-one-foundation-org.firebaseapp.com",
  projectId: "twenty-one-foundation-org",
  storageBucket: "twenty-one-foundation-org.appspot.com",
  messagingSenderId: "386512696722",
  appId: "1:386512696722:web:31cf016e2ac1c4e7c1bd4b",
  measurementId: "G-T6T5B7PQ9M"
};
  
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app);
const timestamp = Timestamp

// init firebase auth
const auth = getAuth()
setPersistence(auth, browserSessionPersistence)

export { 
    db,
    storage,
    timestamp,
    auth
}

export default app

<template>

  <header class="bg-grey 
                   pb-10 
                   z-10
                   relative">

    <Mission />

    <div class="mt-10 
                md:container 
                md:mx-auto 
                relative">

      <img class="h-auto
                  z-20
                  absolute
                  -bottom-72
                  -right-4
                  lg:block
                  hidden" 
            :src="logoIconCropped" 
            alt="Logo icon" />
            
    </div>

    <div class="px-10 
                md:px-28">
      
      <SocialMediaLinks :displayingInHeader="true"
                        :showFollowUs="true"
                        :darkIcons="false"
                        :socialMediaURLS="{ 
                                            linkedIn: 'https://www.linkedin.com/company/the-21-foundation'
                                          }" />
    </div>

  </header>

</template>

<script>
import Mission from './Mission.vue'
import SocialMediaLinks from './SocialMediaLinks.vue'
import logoIconCropped from '@/assets/images/logo/21FoundationLogoIconFull.svg'

export default {
  name: "Header",
  components: {
    Mission,
    SocialMediaLinks
  },
  setup() {
    return {
      logoIconCropped
    }
  }
};
</script>
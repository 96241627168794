<template>

  <footer class="bg-grey">
    <Navigation class="col-start-1" />
    <SocialMediaLinks :displayingInHeader="false"
                      :showFollowUs="true"
                      :darkIcons="false"
                      :socialMediaURLS="{ 
                                          linkedIn: 'https://www.linkedin.com/company/the-21-foundation'
                                        }"
                      class="col-start-1 
                             pb-20
                             px-10
                             md:px-28" />
  </footer>

</template>

<script>
import Navigation from './Navigation.vue'
import SocialMediaLinks from './SocialMediaLinks.vue'

export default {
  name: "Footer",
  components: {
    Navigation,
    SocialMediaLinks
  },
  setup() {
  }
};
</script>
<template>

  <div v-if="article">

    <div class="md:grid
                md:grid-cols-4
                lg:grid-cols-5
                md:gap-10 
                relative">

      <img :src="article.coverImageURL" 
            alt="Logo icon" 
            class="border-8
                   lg:border-16 
                 border-lightgrey
                   col-span-2
                   z-10"/>

      <div class="col-span-2">

        <h2 class="text-xl
                   mt-4
                   md:mt-0
                   sm:text-2xl
                   md:text-3xl
                   lg:text-4xl
                   font-bold
                   mb-8
                   antialiased">
          {{ article.title }}
        </h2>

        <div class="grid 
                    gap-10
                    mt-10">

          <router-link :to="{ name: 'ArticleDetail',
                              params: {
                                articleid: article.articleId
                              } }">

            <Button darkMode="true"
                    paddingX="6" 
                    paddingY="4" 
                    buttonText="View the article" /> 

          </router-link>

          <router-link v-if="displayViewAllArticlesLink"
                        class="featuredArticleLink
                               underline
                               pl-2" 
                      :to="{
                        name: 'News'
                        }">
            View all articles
          </router-link>

        </div>

      </div>

    </div>
    
  </div>
  
</template>

<script>
import Button from './Button.vue'

// vue
import { ref } from 'vue'

export default {
  name: 'FeaturedArticle',
  components: {
    Button
  },
  props: [
    'article',
    'displayViewAllArticlesLink'
  ],
  setup(props) {

    const article = ref(props.article)
    
    const displayViewAllArticlesLink = ref(props.displayViewAllArticlesLink)

    return {
      article,
      displayViewAllArticlesLink
    }
  }
}
</script>


import Home from '../views/Home.vue'
import store from '../store'
import { computed } from 'vue'
import { auth } from '../config/firebase'

// vue-router
import { createRouter, 
         createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: 'News',
    },
    component: () => import(/* webpackChunkName: "News" */ '../views/news/News.vue')
  },
  {
    path: '/news/:articleid',
    name: 'ArticleDetail',
    props: true,
    meta: {
      title: 'News'
    },
    component: () => import(/* webpackChunkName: "ArticleDetail" */ '../views/news/ArticleDetail.vue')
  },
  {
    path: '/foundation',
    name: 'Foundation',
    meta: {
      title: 'Foundation',
    },
    component: () => import(/* webpackChunkName: "Foundation" */ '../views/Foundation.vue')
  },
  {
    path: '/team',
    name: 'Team',
    meta: {
      title: 'Team'
    },
    component: () => import(/* webpackChunkName: "Team" */ '../views/team/Team.vue')
  },
  {
    path: '/team/:name',
    name: 'TeamDetail',
    props: true,
    meta: {
      title: 'Team'
    },
    component: () => import(/* webpackChunkName: "TeamDetail" */ '../views/team/TeamDetail.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Contact'
    },
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },
  // {
  //   path: '/article-preview',
  //   name: 'ArticlePreview',
  //   meta: {
  //     title: 'Article Preview'
  //   },
  //   component: () => import(/* webpackChunkName: "ArticlePreview" */ '../views/admin/news/ArticlePreview.vue')
  // },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      title: 'Admin',
      requiresLogin: true
    },
    component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Admin.vue'),
    children: [
      {
        path: '/admin/news',
        name: 'AdminNews',
        meta: {
          title: 'Admin | News',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "News" */ '../views/admin/news/News.vue')
      },
      {
        path: '/admin/articles/create',
        name: 'AdminCreateArticle',
        meta: {
          title: 'Create Article',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "CreateArticle" */ '../views/admin/news/CreateArticle.vue')
      },
      {
        path: '/admin/articles/edit/:articleid',
        name: 'AdminEditArticle',
        meta: {
          title: 'Edit Article',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "EditArticle" */ '../views/admin/news/EditArticle.vue')
      },]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/admin/authentication/Login.vue')
  },
  {
    // catch all - 4O4
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      title: 'Not Found'
    },
    component: () => import(/* webpackChunkName: "NotFound" */ '../components/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // set page title 
  document.title = `${to.meta.title} | 21 Foundation`
  
  // get the key
  const authUserKey = Object.keys(window.sessionStorage)
    .filter(item => item.startsWith('firebase:authUser'))[0]

  // if a session cookie is present, i.e. a user is signed in. Then, use the key to get the user object value from the session cookie
  const authUser = authUserKey ? JSON.parse(window.sessionStorage.getItem(authUserKey)) : undefined;

  console.log('window.sessionStorage authUser', authUser)

  if(to.matched.some(record => record.meta.requiresLogin) && authUser == null) {

    next({ name: 'Login' })
  } else {

    next()
  }
  
})

export default router

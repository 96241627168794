import { createStore } from 'vuex'
import { auth } from '../config/firebase'
import { 
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged
} from 'firebase/auth'

// import { getDocuments, 
//          deleteDocument, 
//          getAuthenticatedUser } from '../composables/firebaseFirestore';

import { getDocuments, 
         deleteDocument } from '../composables/firebaseFirestore';


// state
const store = createStore({
  state: {
    articles: [],
    article: {
      title: "",
      content: "",
      coverImagePreview: false,
      coverImageFileName: "",
      coverImageURL: null,
      isDraft: false
    },
    profiles: [{
      profileURL: 'drew-lasker',
      name: 'Drew Lasker',
      role: 'Co-Founder, President',
      headshot: '/img/drew-lasker.6f15e4d4.jpg',
      bio: '<p>When Drew retired from professional basketball after his 16th season, he started the next chapter of his career with the commitment to invest in the lives of youth, just like so many had done for him while he was growing up.</p><br /><p>21 Foundation was inspired by Drew’s personal commitment to help student athletes explore the various doors of opportunities that sports will open…even if they do not lead to a career as a professional athlete.</p><br /><p>You must, however, work hard and have faith in God.</p>',
      socialMediaURLS: {
                          linkedIn: 'https://www.linkedin.com/in/drewlasker/',
                          instagram: 'https://www.instagram.com/drewlasker21/',
                          facebook: 'https://www.facebook.com/drewlasker21'
                          // twitter: '#',
                        }
    },
    {
      profileURL: 'yvonne-harris',
      name: 'Yvonne Harris',
      role: 'Co-Founder, Executive Director',
      headshot: '/img/yvonne-harris.3a152da8.jpg',
      bio: '<p>As a parent and active volunteer in her community, Yvonne has a passion for helping women and youth. Through her work with the 21 Foundation, Yvonne seeks to provide student athletes with the skills, resources, and access that will help them create success in all aspects of their lives.</p><br /><p>She wants to help student athletes to understand that sports are a tool that can also help them thrive in school, in their relationships, and in their other career pursuits.</p>',
      socialMediaURLS: {
                          linkedIn: 'https://www.linkedin.com/in/ynharris/',
                          facebook: 'https://www.facebook.com/yvonne.harris.92798'
                        }
    }],
    displayLoadingSpinner: null,
    user: null,
    authIsReady: false
    // profile: {
    //   admin: '',
    //   email: '',
    //   firstName: '',
    //   lastName: '',
    //   initials: ''
    // }
  },
  mutations: {
    
    // articles
    articleTitle(state, payload) {
      state.article.title = payload
    },
    articleCoverImageFileName(state, payload) {
      state.article.coverImageFileName = payload
    },
    toggleCoverImagePreview(state) {
      state.article.coverImagePreview = !state.article.coverImagePreview
    },
    toggleLoadingSpinner(state) {
      state.displayLoadingSpinner = !state.displayLoadingSpinner
    },
    createFileURL(state, payload) {
      state.article.coverImageURL = payload
    },
    articleContent(state, payload) {
      state.article.content = payload
    },
    addArticle(state, payload) {
      /* 
        .unshift()
        adds new article to first position in articles array instead of to the end of the array as with .push()
      */
      state.articles.unshift(payload)
    },
    sortArticlesDesc(state) {
      state.articles.sort((a, b) => b.createdAt - a.createdAt);
    },
    removeArticleFromArticlesArray(state, payload) {
      state.articles = state.articles.filter(article => article.articleId !== payload)
    },
    setArticleState(state, payload) {
      state.article.title = payload.title
      state.article.coverImageURL = payload.coverImageURL
      state.article.coverImageFileName = payload.coverImageFileName
      state.article.content = payload.content
    },

    // staff
    addStaffMember(state, payload) {
      /* 
        .unshift()
        adds new member of staff to first position in staff array instead of to the end of the array as with .push()
      */
      state.staffMembers.unshift(payload)
    },

    // user
    setUser(state, payload) {
      state.user = payload
      console.log('user state changed', state.user)
    },

    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    }

    // user
    // updateUser(state, payload) {
    //   state.user = payload
    // },
    // resetProfile(state) {
    //   state.profile.admin = '',
    //   state.profile.email = '',
    //   state.profile.firstName = '',
    //   state.profile.lastName = '',
    //   state.profile.initials = ''
    // },
    // setProfileInfo(state, userProfile) {
    //   state.profile.firstName = userProfile.firstName
    //   state.profile.lastName = userProfile.lastName
    //   state.profile.email = userProfile.email
    // },
    // setProfileInitials(state) {
    //   state.profile.initials =
    //     state.profile.firstName.match(/(\b\S)?/g).join("") + state.profile.lastName.match(/(\b\S)?/g).join("");
    // }
  },
  getters: {

    // these functions return articles from this VueX store only
    // The articles are returned from Firebase on first load of the application regardless of entry point
    articles: state => (all = false, fromArticle = 0, toArticle = 3) => all ? state.articles : state.articles.slice(fromArticle, toArticle),
    
    // returns a specific article
    article: state => articleid => state.articles.filter(article => article.articleId === articleid),
    
    // returns only the most recent article
    latestArticle: state => state.articles.slice(0, 1)[0],

    // get all articles except the one with the matching articleid value
    // returns only the first 4 articles after this filter has been applied
    additionalArticles: state => articleid => state.articles.filter(article => article.articleId !== articleid).slice(0,4),
    
    // returns all articles except the most recent
    allArticlesExceptLatest: state => state.articles.slice(1),

    // staff
    staff: state => id => id ? state.profiles.filter(profile => profile.id === id) : state.staff,
    staffByName: state => name => state.profiles.filter(profile => profile.profileURL === name)[0],
    staffByRole: state => role => state.profiles.filter(profile => profile.role === role),
    
    // user
    user: state => state.user
  },
  actions: {

    // articles

    // called in app.vue on first load of the application and on any page hard refresh
    getArticlesFromDb: async ({ commit }) => { 
      const articlesFromDb = await getDocuments(store, { 
                                          collectionName: 'news',
                                          orderByField: 'createdAt',
                                          orderByDirection: 'desc'
                                        })

      articlesFromDb.forEach(doc => {

        // get the entire data object for each doc contained within the docsSnapshot 
        // doc.data())

        if(!store.state.articles.some(article => article.articleId === doc.id)) {

            let article = {
                articleId: doc.id,
                title: doc.data().title,
                coverImageURL: doc.data().coverImageURL,
                coverImageFileName: doc.data().coverImageFileName,
                coverImagePreview: false,
                content: doc.data().content,
                createdAt: doc.data().createdAt
            } 
            
            // add current article to the VueX state
            commit('addArticle', article)

            article = null
        }     
      });
          
      // sort the articles in the VueX state, in reverse order by createdAt field value
      commit('sortArticlesDesc')

    },

    updateArticle: async ({ commit, dispatch }, payload) => {

      // remove article from VueX state           
      commit('removeArticleFromArticlesArray', payload)

      // get the articles from the Firebase Firestore with the newly updated content
      await dispatch('getArticlesFromDb')
    },

    deleteArticle: async ({ commit }, { id }) => {

      // delete article from Firebase Firestore
      deleteDocument(store, {
                      collectionName: 'news', 
                      id: id
                    })

      // remove article from VueX state           
      commit('removeArticleFromArticlesArray', id)
    },

    // staff

    // called in app.vue on first load of the application and on any page hard refresh
    getStaffFromDb: async ({ commit }) => { 
      const staffFromDb = await getDocuments(store, { collectionName: 'staff' })

      staffFromDb.forEach(doc => {

        if(!store.state.staff.some(member => member.id === doc.id)) {

            let member = {
                id: doc.id,
                firstName: doc.data().firstName,
                lastName: doc.data().lastName,
                role: doc.data().role,
                profilePictureURL: doc.data().profilePictureURL,
                profilePictureFileName: doc.data().profilePictureFileName,
                bio: doc.data().bio,
                content: doc.data().content,
                createdAt: doc.data().createdAt
            } 
            
            // add current article to the VueX state
            commit('addStaffMember', member)

            member = null
        }     
      });
    },

    updateStaffMember: async ({ commit, dispatch }, payload) => {

      // remove staff member from VueX state           
      commit('removeStaffMemberFromStaffMembersArray', payload)

      // get the staff members from the Firebase Firestore with the newly updated content
      await dispatch('getStaffFromDb')
    },

    deleteStaffMember: async ({ commit }, { id }) => {

      // delete staff member from Firebase Firestore
      deleteDocument(store, {
                      collectionName: 'staff', 
                      id: id
                    })

      // remove staff member from VueX state           
      commit('removeStaffMemberFromStaffMembersArray', id)
    },

    // user
    async login(context, { email, password }) {
      console.log('login action')
      
      // async code
      const res = await signInWithEmailAndPassword(auth, email, password)
      console.log(res)

      if(res) {
          context.commit('setUser', res.user)
      } else {
          throw new Error('could not complete login')
      }
    },

    async logout(context) {
        console.log('logout action')

        await signOut(auth)
        context.commit('setUser', null)
    },

    // async isAuthReady(context) {
    //   console.log('in isAuthReady')
    //   return this.state
    // }

    // getCurrentUser: async () => getAuthenticatedUser(store, { 
    //                                                   collectionName: 'users' 
    //                                                 }),
  },
  modules: {
  }
})

/*
    automattically fired when application first loads

    - user = the Firebase user object - if user is logged in 
    - user = null - if the user is not logged in
*/
const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setAuthIsReady', true)
  store.commit('setUser', user)
  console.log('onAuthStateChanged user', user)
  // without this, the function would be called in an endless loop
  unsub()
})

export default store
<template>

  <div class="grid
              lg:flex
              lg:space-x-8">

    <img :src="profileImage" 
          alt="Profile image"
          class="rounded-full
                h-64
                w-64
                lg:h-52
                lg:w-52
                xl:w-64
                xl:h-64
                mb-5
                mx-auto
                border-16
                border-white" />

    <div class="flex
                flex-col
                flex-grow
                justify-center
                mx-auto">

      <h3 class="font-bold
                  text-xl">{{ fullName }}</h3>

      <h4 class="font-bold
                text-red">{{ role }}</h4>

      <router-link class="text-xs 
                          navigationLink
                          underline
                          pt-4"
                          :to="{ 
                            name: 'TeamDetail', 
                            params: {
                              name: profileURL
                            } 
                          }">
        Find out more
      </router-link>        

      <SocialMediaLinks :showFollowUs="false"
                        :darkIcons="true"
                        :socialMediaURLS="socialMediaURLS"
                        class="pt-4" />

    </div>

  </div>

</template>

<script>
import SocialMediaLinks from './SocialMediaLinks.vue'

export default {
  name: 'TeamShortProfile',
  props: [
    'fullName',
    'profileImage',
    'role',
    'profileURL',
    'socialMediaURLS'
  ],
  components: {
      SocialMediaLinks
  },
  setup(props) {

      const {
          fullName,
          profileImage,
          role,
          profileURL,
          socialMediaURLS
          } = props

      return {
          fullName,
          profileImage,
          role,
          profileURL,
          socialMediaURLS
      }
  }
}
</script>
<template>

    <nav class="bg-grey 
                text-white 
                mx-auto 
                py-6 
                px-10
                md:px-28">

        <div class="flex 
                    items-center
                    justify-between 
                    md:container 
                    md:mx-auto">

            <router-link class="header" 
                         :to="{ name: 'Home' }">

                <img class="h-8 
                            w-auto 
                            cursor-pointer" 
                     :src="logo" alt="Logo" />
                     
            </router-link>
    
            <!-- Nav Links -->
            <div class="hidden lg:flex flex-1 items-center justify-end gap-x-10 ">
                <router-link class="news navigationLink" :to="{ name: 'News' }">News</router-link>
                <router-link class="navigationLink" :to="{ name: 'Foundation' }">The Foundation</router-link>
                <router-link class="team navigationLink" :to="{ name: 'Team' }">The Team</router-link>
                <router-link class="navigationLink" :to="{ name: 'Contact' }">Contact Us</router-link> 
                <a href="https://www.paypal.com/donate/?hosted_button_id=D6GMXY94CKJQ8" 
                   target="_blank">
                    <Button class="my-6" 
                        buttonText="Donate" />
                </a>
            </div>

            <!-- Mobile navigation button-->
            <div class="lg:hidden 
                        flex 
                        items-center">
                <MobileNavigationButton @toggleMobileNavigationMenu="toggleMobileNavigationMenu" />
            </div>

        </div>

        <!-- Mobile navigation menu-->
        <div :class="{ hidden: hideMobileNavigationMenu }" 
              class="lg:hidden 
                     py-6">
            <router-link class="news mobileNavigationLink" :to="{ name: 'News' }">News</router-link>
            <router-link class="mobileNavigationLink" :to="{ name: 'Foundation' }">The Foundation</router-link>
            <router-link class="team mobileNavigationLink" :to="{ name: 'Team' }">The Team</router-link>
            <router-link class="mobileNavigationLink" :to="{ name: 'Contact' }">Contact Us</router-link> 
            <a href="https://www.paypal.com/donate/?hosted_button_id=D6GMXY94CKJQ8" 
               target="_blank">
                <Button class="my-6" 
                        buttonText="Donate" />
            </a>
        </div>

    </nav>

</template>

<script>
// vue
import { ref } from 'vue'

// @ refers to the project root directory
import Button from './Button.vue'
import MobileNavigationButton from './MobileNavigationButton.vue'

import logo from '@/assets/images/logo/21FoundationLogoWhite.svg'
import mobileMenuIcon from '@/assets/images/icons/mobileMenuIcon.svg'

export default {
    name: 'Navigation',
    components: {
        Button,
        MobileNavigationButton
    },
    setup() {
        let hideMobileNavigationMenu = ref(true)

        const toggleMobileNavigationMenu = () => hideMobileNavigationMenu.value = !hideMobileNavigationMenu.value

        return {
            logo,
            mobileMenuIcon,
            hideMobileNavigationMenu,
            toggleMobileNavigationMenu
        }
    }
}
</script>
<template>
  
  <div class="pt-2 
              px-10
              md:px-28 
              md:text-left
              gap-y-6
              gap-x-4
              md:gap-4
              flex
              mx-auto">

    <div class="md:container 
                md:mx-auto
                text-white ">
      
      <h1 class="text-3xl
                 md:text-4xl
                 md:grid
                 lg:grid-cols-2
                 lg:col-start-1
                 lg:col-end-3">
        21 Foundation is dedicated to skills development and mentorship for student athletes
      </h1>
      
    </div>

  </div>

</template>

<script>
export default {
  name: 'Misson'
}
</script>
import { db } from "../config/firebase";
import { doc, addDoc, updateDoc, deleteDoc, getDoc, getDocs, query, orderBy, limit, collection, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";

/*
    getAuthenticatedUser()
    gets current authenticated user, using Firebase Auth
    populate the VueX state - profile object with details from the user returned from Firebase
*/
const getAuthenticatedUser = async (store, { collectionName }) => {

    const auth = getAuth()

    const user = auth.currentUser

    if (user !== null) {

        const uid = user.uid;

        // get the user doc from the FireStore
        const documentReference = doc(db, collectionName, uid)
        const userDocumentSnapShot = await getDoc(documentReference)
        
        if (userDocumentSnapShot.exists()) {
            const userProfile = userDocumentSnapShot.data()

            // populate the VueX state with the userProfile
            store.commit("setProfileInfo", userProfile)
            store.commit("setProfileInitials")
        }
    }
}

/*
    addDocument()
    add a single document to the Firebase Firestore
    return the document in the promise resolve or return an error in the promise reject
*/
const addDocument = async ({ collectionName, data }) => {

    return new Promise( async(resolve, reject) => {
        try {
            // Add a new document with a generated id.
            const docAdded = await addDoc(collection(db, collectionName), data);
                    
            // when successful emit event here 
            resolve(docAdded)

        } catch(error) {
            reject(error)
        }
        
    })
}

/*
    updateDocument()
    update some fields in a single document in the Firebase Firestoreof without overwriting the entire document,
    return the updated document in the promise resolve or return an error in the promise reject
    ref: https://firebase.google.com/docs/reference/js/firestore_#updatedoc
*/
const updateDocument = async ({ collectionName, data, documentFirebaseId }) => {

    return new Promise( async(resolve, reject) => {
        try {
            // Create a new document reference
            const documentRef = doc(db, collectionName, documentFirebaseId)

            // update the document with data provided
            await updateDoc(documentRef, data)

            // when successful emit event here 
            resolve()

        } catch(error) {
            reject(error)
        }
        
    })
}

/*
    getDocuments()
    get all articles from the Firebase Firestore
    add each article to the VueX state
*/
const getDocuments = async (store, { collectionName, orderByField, orderByDirection }) => {

    const q = query(collection(db, collectionName), orderBy(orderByField, orderByDirection))

    const docsSnapshot = await getDocs(q)

    return docsSnapshot
}

/*
    deleteDocument()
    delete the Article in Firebase with the articleId provided in payload
    update the state.articles array to remove the article with the articleId provided in payload
*/
const deleteDocument = async (store, { collectionName, id }) => {
    await deleteDoc(doc(db, collectionName, id))
    // store.commit('removeArticleFromArticlesArray', id)
}

export {
    getAuthenticatedUser,
    getDocuments,
    addDocument,
    updateDocument,
    deleteDocument
}

<template>

  <div class="pb-16 
              md:pb-20
              z-1
              relative">

    <div class="bg-white 
                  container 
                  mx-auto
                  relative">

      <img class="h-auto
                  absolute
                  hidden
                  opacity-60
                  -top-32
                  -right-10
                  lg:block"
          :src="logoIconShadowCropped" 
           alt="Logo icon" />
    </div>

    <div class="px-10 
                md:px-28">

      <div class="pt-16
                  md:pt-20
                  md:container 
                  md:mx-auto
                  md:grid
                  md:grid-cols-4">

        <h1 class="text-3xl
                   text-grey
                   mb-6
                   md:mb-10
                   md:col-start-1
                   md:col-end-3">
          OUR MISSION
        </h1>

        <p class="col-start-1
                  col-end-4
                  mb-4
                  md:mb-2">
          Paying tribute to the lives and legacies of Drew’s grandmother, Vera Lott, and Yvonne’s mother, Dr. Carol Yvonne Plummer Harris.
        </p> 
        <p class="col-start-1
                  col-end-4
                  md:mb-2
                  md:mt-2
                  lg:mt-1">
          21 Foundation serves deserving student athletes by providing the skills and resources they need to be successful on and off the court.
        </p>



      </div>
      <div class="pt-10
                  md:pt-16
                  md:pt-5
                  md:container 
                  md:mx-auto">

        <p class="font-bold mb-2">Our services include: </p>
        <ul class="list-disc
                    list-outside
                    mx-6">
          <li>Reading programs</li>
          <li>Basketball camps and skills development training sessions</li>
          <li>Sports equipment distribution to underserved communities</li>
        </ul>

    </div>
    
  </div>
      
  </div>

</template>

<script>
import logoIconShadowCropped from '@/assets/images/logo/21FoundationLogoIconShadowCropped.svg'

export default {
  name: 'MissionStatement',
  setup() {
    return {
      logoIconShadowCropped
    }
  }
}

</script>
<template>

<div class="pb-10">

  <div class="md:container 
              md:mx-auto">
 
    <p v-if="showFollowUs" 
       class="mb-4 text-red">Follow us</p>

    <span class="flex mx-auto gap-x-2">
      <a v-if="linkedInURL" :href="linkedInURL">
        <img class="h-9 w-auto text-red" :src="linkedInLogoIcon" alt="LinkedIn icon" />
      </a>
      <a v-if="instagramURL" :href="instagramURL">
        <img class="h-9 w-auto" :src="instagramLogoIcon" alt="Instagram icon" />
      </a>
      <a v-if="twitterURL" :href="twitterURL">
        <img class="h-9 w-auto" :src="twitterLogoIcon" alt="Twitter icon" />
      </a>
      <a v-if="facebookURL" :href="facebookURL">
        <img class="h-9 w-auto" :src="facebookLogoIcon" alt="Facebook icon" />
      </a>
    </span>
  
  </div>

</div>

</template>

<script>
// vue
import { computed, ref } from 'vue'

import linkedInIconDark from '@/assets/images/icons/linkedInIcon-dark.svg'
import instagramIconDark from '@/assets/images/icons/instagramIcon-dark.svg'
import twitterIconDark from '@/assets/images/icons/twitterIcon-dark.svg'
import facebookIconDark from '@/assets/images/icons/facebookIcon-dark.svg'
import linkedInIcon from '@/assets/images/icons/linkedInIcon.svg'
import instagramIcon from '@/assets/images/icons/instagramIcon.svg'
import twitterIcon from '@/assets/images/icons/twitterIcon.svg'
import facebookIcon from '@/assets/images/icons/facebookIcon.svg'


export default {
  name: 'SocialMediaLinks',
  props: [
    'showFollowUs',
    'darkIcons',
    'socialMediaURLS'
  ],
  setup(props) {
    const showFollowUs = ref(props.showFollowUs)
    const darkIcons = ref(props.darkIcons)
    const linkedInURL = ref(props.socialMediaURLS.linkedIn)
    const instagramURL = ref(props.socialMediaURLS.instagram)
    const twitterURL = ref(props.socialMediaURLS.twitter)
    const facebookURL = ref(props.socialMediaURLS.facebook)

    // computed
    const linkedInLogoIcon = computed(() => props.darkIcons ? linkedInIconDark : linkedInIcon)
    const instagramLogoIcon = computed(() => props.darkIcons ? instagramIconDark : instagramIcon)
    const twitterLogoIcon = computed(() => props.darkIcons ? twitterIconDark : twitterIcon)
    const facebookLogoIcon = computed(() => props.darkIcons ? facebookIconDark : facebookIcon)

    return {
      showFollowUs,
      darkIcons,
      linkedInURL,
      instagramURL,
      twitterURL,
      facebookURL,
      linkedInLogoIcon,
      instagramLogoIcon,
      twitterLogoIcon,
      facebookLogoIcon
    }
  }
}
</script>


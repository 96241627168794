<template>

  <div class="min-h-full">

    <Navigation v-if="displayNavigationAndFooter" />

    <router-view />

    <Footer v-if="displayNavigationAndFooter" />

  </div>

</template>

<script>
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

// firebase
// import { getAuth, 
//          onAuthStateChanged, 
//          setPersistence, 
//          signInWithEmailAndPassword, 
//          browserSessionPersistence } from "firebase/auth";

export default {
  components: {
    Navigation,
    Footer
  },
  data() {
    return {
      displayNavigationAndFooter: true,
      isSignedIn: false
    }
  },
  created() {
    this.$store.dispatch('getArticlesFromDb')
    
    // const auth = getAuth();

    // ref: https://firebase.google.com/docs/auth/web/auth-state-persistence
    // setPersistence(auth, browserSessionPersistence)
    //   .then(() => {
    //     // Existing and future Auth states are now persisted in the current
    //     // session only. Closing the window would clear any existing state even
    //     // if a user forgets to sign out.
    //     // New sign-in will be persisted with session persistence.
    //     return signInWithEmailAndPassword(auth, email, password);
    //   })
    //   .catch((error) => {
    //     // Handle Errors here.
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //   });

  //   onAuthStateChanged(auth, user => {
  //     this.$store.commit('updateUser', user)

  //     if (user) {
  //       this.$store.dispatch("getCurrentUser")
  //       this.isSignedIn = true
  //     } else {
  //       // User is signed out
  //       console.log('User is signed out')
  //     }

  //     this.checkRoute()
  // });
  },
  methods: {
    checkRoute() {
      if(this.$route.name === 'Login' ||
         this.$route.name === 'Register' ||
         this.$route.name === 'ForgotPassword' ||
         this.$route.name === 'Admin' ||
         this.$route.name === 'AdminNews' ||
         this.$route.name === 'AdminCreateArticle' ||
         this.$route.name === 'AdminEditArticle') {
           this.displayNavigationAndFooter = false
           return
         } else {
            this.displayNavigationAndFooter = true
        }
    }
  },
  watch: {
    $route() {
      this.checkRoute()
    }
  }
}
</script>

<template>

  <Header />

  <MissionStatement />

  <TeamComponent />

  <div v-if="latestArticle"
       class="px-16
              md:px-28 
              pb-16
              md:pb-28
            bg-red
            text-white
              relative">

    <div class="mb-10
                pt-16
                md:pt-20
                md:container 
                md:mx-auto"> 

      <h1 class="text-3xl
                  mb-4
                  md:mb-10
                  md:col-start-1
                  md:col-end-3
                  antialiased">
        NEWS
      </h1>

    </div>

    <div class="pt-0
                md:pt-6
                md:container 
                md:mx-auto">

      <div v-if="latestArticle">

        <FeaturedArticle :article="latestArticle"
                         :displayViewAllArticlesLink=true />

      </div>

    </div>

  </div>

</template>

<script>
import Header from '@/components/Header.vue'
import MissionStatement from '@/components/MissionStatement.vue'
import TeamComponent from '@/components/TeamComponent.vue'
import FeaturedArticle from '@/components/FeaturedArticle.vue'

// vue
import { computed } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    MissionStatement,
    TeamComponent,
    FeaturedArticle
  },
  setup() {
    const store = useStore()

    const latestArticle = computed(() => store.getters.latestArticle)

    return {
      latestArticle
    }
  }
}
</script>
